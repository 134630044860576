<template>
  <div>
    <b-modal v-model="modalShow" size="md" :title="$t('dataview.select_column_title')" 
      footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
      :ok-title="$t('button.select')"
      scrollable
    >
      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
      
      <label>{{ $t('dataview.field.field') }}</label>  
      <div class="field-select-container">  
        <div class="d-inline-block" v-bind:key="index" v-for="(field, index) in fieldlist">
          <div class="column-list">
            <div class="d-inline-block"
              :class="getEditFieldClass(index)"
              @click.stop="editField(index)">
              <div
                class="field-label d-inline-block">
                {{ field !== null && field !== '' ? field.replace(/\(A\)|\(A,B\)|\(A,B,C\)/, "()") : $t('dataview.placeholder.field') }}
              </div>
              <div v-if="showEditFieldIcon(index)" :id="`EDIT_FIELD_${id}_${index}`" class="d-inline-block ml-2 mr-2">
                <font-awesome-icon class="edit-icon-color" :icon="['far', 'pen-to-square']"/>
                <b-popover
                  :target="`EDIT_FIELD_${id}_${index}`"
                  placement="top"
                  triggers="hover"
                  :content="$t('button.edit')">
                </b-popover>
              </div>
            </div>
          </div>
          <div v-if="index !== fieldlist.length - 1" class="field-dot">.</div>
        </div>
      </div>
     
      <div class="mt-2 field-modal">
        <b-tabs v-if="editIndex !== -1"
          v-model="activeTab" 
          active-nav-item-class="active"
          content-class="mt-3" pills>
          <b-tab :title="$t('dataview.field.fields')" class="content-tab">
            <b-input-group>
              <b-form-input
                size="md"
                v-model="fieldfilter"
                :placeholder="$t('dataview.search')">
              </b-form-input>
              <b-input-group-append>
                <b-input-group-text>
                  <font-awesome-icon :icon="['far', 'magnifying-glass']"/>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          
            <b-form-checkbox-group v-if="showEntities" class="mt-2 child-entity row-coloring" :options="getChildren" v-model="selectedChild" stacked @change="changeChild"></b-form-checkbox-group>
            <b-form-checkbox-group v-if="properties" :class="getChildren.length % 2 === 0 ? 'row-coloring' : 'row-coloring-alt'" :options="getFields" v-model="selectedField" stacked @change="changeField"></b-form-checkbox-group>
          </b-tab>
          <b-tab :title="$t('dataview.recent')" class="content-tab">
            <b-input-group>
              <b-form-input
                size="md"
                v-model="fieldfilter"
                :placeholder="$t('dataview.search')">
              </b-form-input>
              <b-input-group-append>
                <b-input-group-text>
                  <font-awesome-icon :icon="['far', 'magnifying-glass']"/>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          
            <b-form-checkbox-group class="mt-2" :options="getRecent" v-model="selectedField" stacked @change="changeRecent"></b-form-checkbox-group>
          </b-tab>
        </b-tabs>
        <div v-else>
          <b-form-checkbox v-if="useAgFunc && supportsFunctions()" class="mt-3 mb-2" v-model="applyFunction" @input="updateName">{{ $t('dataview.applyfunction') }}</b-form-checkbox>
          <b-form-select v-if="useAgFunc && supportsFunctions()" :disabled="!applyFunction" name="function" v-model="agFunction" :options="agFunctions()" @change="updateName"></b-form-select>
          
          <b-form-group v-if="useName" class="mt-3" :label="$t('dataview.displayname')" label-for="name">
            <b-form-input
              v-if="useName"
              size="md"
              v-model="name"
              name="name"
              :placeholder="$t('dataview.field.name')">
            </b-form-input>
          </b-form-group>
        </div>
        <div v-if="useOrder">
          <label class="d-block">{{ $t(`dataview.field.direction`) }}</label>
          <b-form-select size="md" class="w-auto mb-3" v-model="sortdirection" :options="sortDirectionOptions"></b-form-select>
        </div>
      </div>
                
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button :disabled="disableColumnOK()" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>

  </div>
</template>

<script>

import { cloneDeep } from 'lodash';
import { recentfieldProfileService } from '@/services';
import { getFieldType, getMacroType, isBlacklisted } from '@/helpers';

function binarySearch(items, value) {
  let startIndex = 0;
  let stopIndex = items.length - 1;
  let middle = Math.floor((stopIndex + startIndex) / 2);

  while (items[middle] != value && startIndex < stopIndex) {

    //adjust search area
    if (value < items[middle]) {
      stopIndex = middle - 1;
    } else if (value > items[middle]) {
      startIndex = middle + 1;
    }

    //recalculate middle
    middle = Math.floor((stopIndex + startIndex) / 2);
  }

  // Return -1 if element is not in collection
  // return (items[middle] != value) ? -1 : middle;
  return {
      found: items[middle] == value,
      middle: middle
  }
}

const calendarProperties = [
  {
    "field": "color",
    "type": "String",
    "min": 7,
    "max": 7
  },
  {
    "field": "endDate",
    "type": "Date",
    "min": 0,
    "max": 32503680000000
  },
  {
    "field": "endHour",
    "type": "Integer",
    "min": 0,
    "max": 86400000
  },
  {
    "field": "identifier",
    "type": "String",
    "min": 0,
    "max": 200,
    "index": "Composite"
  },
  {
    "field": "isWorking",
    "type": "Boolean",
    "options": [
      "true",
      "false"
    ]
  },
  {
    "field": "name",
    "type": "String",
    "notNull": true,
    "min": 0,
    "max": 200,
    "index": "Mixed"
  },
  {
    "field": "startDate",
    "type": "Date",
    "min": 0,
    "max": 32503680000000
  },
  {
    "field": "startHour",
    "type": "Integer",
    "min": 0,
    "max": 86400000
  },
  {
    "field": "type",
    "type": "Enum",
    "options": [
      "Leave",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Working"
    ],
    "notNull": true
  },
  {
    "field": "uuId",
    "type": "UUID",
    "readOnly": true,
    "index": "Composite"
  }
];

export default {
  name: 'FieldSelectModal',
  components: {

  },
  props: {
    show:         { type: Boolean, required: true },
    schema: { type: Object, default: null },
    customFields: { type: Object, default: null },
    field:       { type: [String, Object], default: null },
    userId:       { type: String, default: null },
    properties: { type: Boolean, default: true },
    showEntities: { type: Boolean, default: true },
    fieldName: { type: String, default: null },
    useName: { type: Boolean, default: false },
    root: { type: String, default: null },
    macros: { type: Object, default: null },
    agFunc: {type: Object, default: null },
    useAgFunc: { type: Boolean, default: true },
    useCustomOrder: { type: Boolean, default: false },
    useOrder: { type: Boolean, default: false },
    isFilter: { type: Boolean, default: false }
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 9),
      uuId: null, // id for recent profile
      name: null,
      alertError: false,
      alertMsg: null,
      modalShow: false,
      fieldlist: [],
      activeTab: 0,
      editIndex: -1,
      selectedChild: [],
      selectedField: [],
      fieldfilter: "",
      recent: [],
      rootLength: -1,
      rootEntities: [
        "ACTIVITY",
        "BOOKING",
        "COMPANY",
        "CUSTOMER",
        "DEPARTMENT",
        "LOCATION",
        "PROJECT",
        "PROJECT_TEMPLATE",
        "RESOURCE",
        "SKILL",
        "STAFF",
        "STAGE",
        "STORAGE_FILE",
        "TAG",
        "TASK",
        "USER"
      ],
      applyFunction: false,
      agFunction: null,
      sortdirection: 'incr',
      sortDirectionOptions: [{text: 'Ascending', value: 'incr'},
                             {text: 'Descending', value: 'decr'}],
      blacklist: ['LOCATION-STAFF',
                  'STAFF-LOCATION',
                  'STAFF-DEPARTMENT',
                  'STAFF-NOTE',
                  'LOCATION-COMPANY',
                  'LOCATION-CONTACT',
                  'LOCATION-CUSTOMER',
                  'LOCATION-NOTE',
                  'LOCATION-PROJECT',
                  'LOCATION-PROJECT_TEMPLATE',
                  'COMPANY-COMPANY',
                  'COMPANY-CONTACT',
                  'COMPANY-DEPARTMENT',
                  'COMPANY-LOCATION',
                  'COMPANY-NOTE',
                  'COMPANY-PROJECT',
                  'CONTACT-COMPANY',
                  'CONTACT-CUSTOMER',
                  'CONTACT-LOCATION',
                  'CONTACT-NOTE',
                  'CUSTOMER-CONTACT',
                  'CUSTOMER-LOCATION',
                  'CUSTOMER-NOTE',
                  'CUSTOMER-PROJECT',
                  'CUSTOMER-PROJECT_TEMPLATE',
                  'NOTE-COMPANY',
                  'NOTE-CONTACT',
                  'NOTE-CUSTOMER',
                  'NOTE-DEPARTMENT',
                  'NOTE-LOCATION',
                  'NOTE-PROJECT',
                  'NOTE-PROJECT_TEMPLATE',
                  'NOTE-REBATE',
                  'NOTE-RESOURCE',
                  'NOTE-STAFF',
                  'NOTE-STORAGE_FILE',
                  'NOTE-STORAGE_FOLDER',
                  'NOTE-TASK',
                  'NOTE-TASK_TEMPLATE',
                  'NOTE-USER',
                  'PROJECT-COMPANY',
                  'PROJECT-CUSTOMER',
                  'PROJECT-LOCATION',
                  'PROJECT-NOTE',
                  'PROJECT-REBATE',
                  'PROJECT-STAGE',
                  'PROJECT_TEMPLATE-CUSTOMER',
                  'PROJECT_TEMPLATE-LOCATION',
                  'PROJECT_TEMPLATE-NOTE',
                  'PROJECT_TEMPLATE-REBATE',
                  'DEPARTMENT-COMPANY',
                  'DEPARTMENT-LOCATION',
                  'DEPARTMENT-DEPARTMENT',
                  'DEPARTMENT-NOTE',
                  'DEPARTMENT-STAFF',
                  'RESOURCE-NOTE',
                  'TASK-STAGE',
                  'TASK-NOTE',
                  'TASK-REBATE',
                  'REBATE-NOTE',
                  'REBATE-PROJECT',
                  'REBATE-PROJECT_TEMPLATE',
                  'REBATE-TASK',
                  'REBATE-TASK_TEMPLATE',
                  'STAGE-TASK',
                  'STAGE-PROJECT',
                  'USER-NOTE']
    }
  },
  created() {
  
  },
  beforeDestroy() {

  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.rootLength = this.root !== null ? this.root.split('.').length : -1;
        if (this.field !== null && this.field !== "") {
          if (typeof this.field === 'object') {
            this.fieldlist = this.field.field.split('.');
            this.sortdirection = this.field.sortdirection;
          }
          else {
            this.fieldlist = this.field.split('.');
          }
          this.editIndex = -1;
        }
        else if (this.root !== null) {
          this.fieldlist = this.root.split('.');
          this.fieldlist.push(null);
          this.editIndex = this.rootLength;
        }
        else {
          this.fieldlist = [null];
          this.editIndex = 0;
        }
        
        this.name = this.fieldName !== null ? this.fieldName : this.field;
        this.selectedChild = [];
        this.selectedField = [];
        this.recent = [];
        this.listRecentfields();
        this.fieldfilter = "";
        this.applyFunction = this.agFunc !== null ? this.agFunc.applyFunction : false;
        this.agFunction = this.agFunc !== null ? this.agFunc.agFunction : null;
        this.alertMsg = null;
        this.modalShow = newValue;
      }
    }
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    },
    entities() {
      return this.rootEntities;
    },
    getChildren() {
      const index = this.editIndex;
      if (index === 0) {
        return this.fieldfilter === '' ? this.entities : this.entities.filter(e => e.toLowerCase().includes(this.fieldfilter.toLowerCase()));
      }
      else {
        if (this.schema !== null) {
          
          var entity = this.fieldlist[index - 1];
          const permList = this.$store.state.authentication.user.permissionList.filter(f => f.name === `${entity}__VIEW`);
          const perms = permList.length > 0 ? 
                        permList[0] : 
                        [];
          const denyRules = perms && perms.permissionLink && perms.permissionLink.denyRules ?
                            perms.permissionLink.denyRules : [];
          
          if (entity === 'CALENDAR') {
            return [];
          }       
                 
          let connections = this.schema[entity].connections;
          if (entity === 'STAFF' &&
                   !connections.includes('CALENDAR')) {
            connections = cloneDeep(connections);
            connections.push('CALENDAR');
            connections.sort();       
          }
          
          if (typeof connections !== 'undefined') {
            const connectionsFiltered = connections.filter(c => !this.blacklist.includes(c) && !denyRules.includes(c) && isBlacklisted(entity, c) == false);
            return this.fieldfilter === '' ? connectionsFiltered : connectionsFiltered.filter(c => c.toLowerCase().includes(this.fieldfilter.toLowerCase()));
          }
        }
      }
      
      return [];
    },
    getFields() {
      const index = this.editIndex;
      if (index === 0) {
        return [];
      }
      
      var entity = this.fieldlist[index - 1];
      if (entity !== null && this.schema !== null) {
        var schemaObj = this.schema[entity];
        if (typeof schemaObj === 'string') {
          entity = schemaObj;
          schemaObj = this.schema[entity];
        }
        
        
        // macros
        const keys = [];
        for (var key in this.macros) {
          if (key.toLowerCase().includes(this.fieldfilter.toLowerCase()) &&
              (this.macros[key].arguments[0].type.split(',').includes(entity) ||
              (this.macros[key].arguments[0].type === "PATH.MODEL.property" &&
               this.macros[key].arguments.length === 1 && key !== '=stamp(A)' && key !== '=fullPath(A)' &&
               this.fieldlist[index - 1] === 'TASK'))) {
            keys.push(key);
            if (key === '=actualCost(A,B,C)') {
              keys.push('=actualCostNet(A,B,C)');
            }
            else if (key === '=fixedCost(A,B,C)') {
              keys.push('=fixedCostNet(A,B,C)');
            }
            else if (key === '=estimatedCost(A,B,C)') {
              keys.push('=estimatedCostNet(A,B,C)');
            }
            else if (key === '=plannedCost(A,B,C)') {
              keys.push('=plannedCostNet(A,B,C)');
            }
          }
        }
        
        if (this.isFilter &&
            (entity === 'STAFF' ||
            entity === 'TASK' ||
            entity === 'PROJECT' ||
            entity === 'ACTIVITY' ||
            entity === 'BOOKING')) {
          keys.push('=active()');
          keys.push('=ended()');
          keys.push('=started()');   
        }
        
        const permList = this.$store.state.authentication.user.permissionList.filter(f => f.name === `${entity}__VIEW`);
        const perms = permList.length > 0 ? 
                      permList[0] : 
                      [];
        const denyRules = perms && perms.permissionLink && perms.permissionLink.denyRules ?
                          perms.permissionLink.denyRules : [];
                          
        const properties = entity === 'CALENDAR' ? calendarProperties : schemaObj.properties;
        if (properties !== null && typeof properties !== 'undefined') {
          const filteredProperties = properties.filter(entry => entry.type !== 'UUID' && entry.field !== 'propertyType' && isBlacklisted(entity, entry.field) === false && !denyRules.includes(entry.field));
          const fields = filteredProperties.map(p => { return p.field });
          
          // support for custom order
          if (this.useCustomOrder && schemaObj.isCustomOrder) {
            const result = binarySearch(fields, 'order');
            fields.splice(result.middle + 1, 0, 'order');
          }
          
          const retFields = this.fieldfilter === '' ? fields : fields.filter(f => f.toLowerCase().includes(this.fieldfilter.toLowerCase()))
          return retFields.concat(keys).map(f => { return { value: f, text: f.replace(/\(A\)|\(A,B\)|\(A,B,C\)/, "()") } });  // include the macros
        }
      }
      
      return [];
    },
    getRecent() {
      if (this.root !== null) {
        return this.fieldfilter === '' ? this.recent.filter(r => r.startsWith(this.root)) : this.recent.filter(r => r.startsWith(this.root) && r.toLowerCase().includes(this.fieldfilter.toLowerCase()));
      }
      return this.fieldfilter === '' ? this.recent : this.recent.filter(r => r.toLowerCase().includes(this.fieldfilter.toLowerCase()));
    }
  },
  methods: {
    agFunctions() {
      const field = this.fieldlist.filter(f => f !== null).join('.');
      if (this.isNumberField(field)) {
        const rootLength = this.root !== null ? this.root.split('.').length : 0;
        const isChild = rootLength !== this.fieldlist.length - 1;  // is a child if an entity below the root is selected
        if (isChild) {
          return [
            { text: "COS()", value: "cos" },
            { text: "COUNT()", value: "count" },
            { text: "MAX()", value: "max" },
            { text: "MEAN()", value: "mean" },
            { text: "MIN()", value: "min" },
            { text: "SIN()", value: "sin" },
            { text: "SUM()", value: "sum" },
            { text: "TAN()", value: "tan" }
          ];
        }
        else {
          return [
            { text: "COS()", value: "cos" },
            { text: "SIN()", value: "sin" },
            { text: "TAN()", value: "tan" }
          ];
        }
      }
      else if (getFieldType(field, this.schema) === 'Date') {
        return [
          { text: "YEAR()", value: "year" },
          { text: "MONTH()", value: "month" },
          { text: "DAY()", value: "day" },
          { text: "HOUR()", value: "hour" },
          { text: "MINUTE()", value: "minute" }
        ];
      }
      else {        
        const rootLength = this.root !== null ? this.root.split('.').length : 0;
        const isChild = rootLength !== this.fieldlist.length - 1;  // is a child if an entity below the root is selected
        if (isChild) {
          return [
            { text: "COUNT()", value: "count" },
            { text: "LENGTH()", value: "length" }
          ];
        }
        else {
          return [
            { text: "LENGTH()", value: "length" }
          ];
        }
      }
    },
    supportsFunctions() {
      const field = this.fieldlist.filter(f => f !== null).join('.');
      var result = false;
      var type = getFieldType(field, this.schema);
      result = type !== 'Boolean' && type !== 'Enum' && 
               !field.includes('.=active()') &&
               !field.includes('.=started()') &&
               !field.includes('.=ended()');
      return result;
    },
    isNumberField(field) {
      var result = false;
      var type = getFieldType(field, this.schema);
      if (type === 'Macro') {
        type = getMacroType(field, this.macros);
      }
      
      if (type === 'Duration' || type === 'MinuteDuration' || type === 'Progress' || type === 'Integer' || type === 'Long' || type === 'Double' || type === 'Float') {
        result = true;
      }
    
      return result;
    },
    disableColumnOK() {
      // if no fields or the last field is not a property then disable
      return this.fieldlist.length === 0 || 
             (this.properties && (this.fieldlist[this.fieldlist.length - 1] === null || typeof this.schema[this.fieldlist[this.fieldlist.length - 1]] !== 'undefined')) ||
             (this.applyFunction && this.agFunction === null);
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      const value = this.fieldlist.filter(f => f !== null).join('.');
      if (!this.recent.includes(value)) {
        this.recent.push(value);
      }
      
      if (this.uuId === null) {
        this.createRecentfieldProfile({ recent: this.recent, properties: this.properties });
      }
      else {
        this.updateRecentfieldProfile({ uuId: this.uuId, recent: this.recent, properties: this.properties });
      }
    },   
    listRecentfields() {
      const self = this;
      recentfieldProfileService.list(this.userId).then((response) => {  
        const profileData = response.data[response.data.jobCase];
        if (profileData.length > 0) {
          const profile = profileData.filter(p => p.properties === this.properties);
          if (profile.length > 0) {
            self.recent = typeof profile[0].recent !== 'undefined' ? profile[0].recent.reverse() : [];
            self.uuId = profile[0].uuId;
          }
        }
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    }, 
    createRecentfieldProfile(recent) {
      const self = this;
      recentfieldProfileService.create([ recent,
                                           ],
                        this.userId).then(() => {  
        self.$emit('update:show', false);
        self.$emit('success', { 
                                result: self.fieldlist.filter(f => f !== null).join('.'), 
                                original: this.field, 
                                originalAgFunc: this.agFunc,
                                name: this.name,  
                                 agFunc: {
                                   applyFunction: this.applyFunction,
                                   agFunction: this.agFunction
                                 },
                                 sortdirection: this.sortdirection
                              });
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    updateRecentfieldProfile(recent) {
      const self = this;
      recentfieldProfileService.update([recent],
                        this.userId).then(() => {  
        self.$emit('update:show', false);
        self.$emit('success', { 
                                 result: self.fieldlist.filter(f => f !== null).join('.'), 
                                 original: this.field, 
                                 originalAgFunc: this.agFunc,
                                 name: this.name, 
                                 agFunc: {
                                   applyFunction: this.applyFunction,
                                   agFunction: this.agFunction
                                 },
                                 sortdirection: this.sortdirection
                               });
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    }, 
    addField() {
      this.fieldlist.push(null);
    },
    removeField(index) {
      this.fieldlist.splice(index, 1);
    },
    itemClicked(index, item) {
      this.$set(this.fieldlist, index, item);
    },
    dismissAlert() {
      this.alertMsg = null;
        this.alertError = false;
    },
    editField(index) {
      if (index < this.rootLength &&
          this.root !== null) {
        return; // cannot edit root field   
      }
      this.editIndex = index;
      this.selectedChild = [];
      this.selectedField = [];
      this.fieldlist.length = index + 1;
    },
    getEditFieldClass(index) {
      if (index < this.rootLength &&
          this.root !== null) {
        return 'root-edit';   
      }
      else if (index === this.editIndex) {
        return 'editing-field clickable';
      }
      return 'clickable';
    },
    showEditFieldIcon(index) {
      if (index < this.rootLength &&
          this.root !== null) {
        return false;   
      }
      return true;
    },
    hasChildren(entity) {
      const connections = this.schema[entity].connections;
      if (typeof connections !== 'undefined' &&
          connections.length > 0) {
        return true;
      }
      return false;
    },
    changeChild(children) {
      if (children.length !== 0) {
        this.fieldlist[this.editIndex] = children[0];
        this.updateName();
        
        if (this.fieldlist.length === this.editIndex + 1 &&
            (this.properties || this.hasChildren(children[0]))) {
          this.fieldlist.push(null);
          this.$nextTick(() => {
            this.selectedChild = [];
          });
          this.editIndex++;
        }
        this.fieldfilter = "";
      }
      else {
        this.fieldlist[this.editIndex] = null;
      }
    },
    changeField(fields) {
      if (fields.length > 0) {
        this.fieldlist[this.editIndex] = fields[0];
        this.applyFunction = false;
        this.agFunction = null;
        this.updateName();
        
        // reset
        this.editIndex = -1;
        this.fieldfilter = "";
      }
      else {
        this.fieldlist[this.editIndex] = null;
      }
    },
    changeRecent(recent) {
      if (recent.length > 0) {
        this.editIndex = -1;
        this.fieldlist = recent[0].split('.');
        this.fieldfilter = "";
        this.updateName();
      }
    },
    correctName(entity) {
      if (entity === 'PROJECT_TEMPLATE') {
        return 'TASK TEMPLATE';
      }
      else if (entity === 'TASK_TEMPLATE') {
        return 'TASK';
      }
      else if (entity === 'STORAGE_FILE') {
        return 'FILE';
      }
      return entity;
    },
    updateName() {
        const entity = this.correctName(this.fieldlist.length > 1 ? this.fieldlist[this.fieldlist.length - 2] : this.fieldlist[0]);
        const field = this.fieldlist[this.fieldlist.length - 1];
        const words = field.replace(/\s*\(.*?\)\s*/g, '').replace(/^=*/g, "").match(/[A-Z]*[^A-Z]+/g);
        const name = words !== null ? words.join(' ') : field;
        const customField = this.customFields !== null ? this.customFields[`${entity}.${field}`] : null;
        this.name = customField ? customField.profile.displayName : `${entity[0] + entity.substr(1).toLowerCase()} ${name[0].toUpperCase()}${name.substr(1)}`;
        
        // special case for cost fields
        if (field.includes('CostNet')) {
          this.name = this.name.replace('Net', '(Net)');
        }
        else if (field.includes('Cost')) {
          this.name += ' (Gross)';
        }
        
        if (this.applyFunction && this.agFunction !== null) {
          this.name = `${this.name} ${this.agFunction[0].toUpperCase()}${this.agFunction.substr(1)}`;
        }
    }
  }
}
</script>

<style lang="scss">
.field-modal {
  min-height: 350px;
}

.editing-field {
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 0 0.2rem var(--form-control-focus-shadow);
  box-shadow: 0 0 0 0.2rem var(--form-control-focus-shadow);
}

.field-select-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--form-control-border);
  border-radius: 3px;
  padding: 3px;
}

.field-dot {
  display: inline-block;
  font-weight: bold;
  vertical-align: text-bottom;
  margin: 2px;
}

.field-label {
  padding: 7px;
  margin-right: 5px;
}

.column-list {
  display: inline-block;
  margin: 1px;
  border: 1px solid var(--border-medium);
  border-radius: 3px;
}

.child-entity {
  font-style: italic;
}

.content-tab {
  max-height: 300px;
  overflow-y: auto;
}

.root-edit {
  background-color: var(--form-control-disabled-bg);
}

.row-coloring>div:nth-of-type(even) {
  background-color: var(--ag-background-color);
}

.row-coloring>div:nth-of-type(odd) {
  background-color: var(--ag-odd-row-background-color);
}
.row-coloring-alt>div:nth-of-type(even) {
  background-color: var(--ag-odd-row-background-color);
}

.row-coloring-alt>div:nth-of-type(odd) {
  background-color: var(--ag-background-color);
}
</style>
